<template>
  <div id="user-info">
    <h1>Thông tin tài khoản</h1>
    <p>Tính năng sẽ có sớm...</p>
  </div>
</template>

<script>
export default {
  name: "user info",
  data() {
    return {};
  },
};
</script>
